import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import QRCode from "qrcode";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Profiles() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempUsers = [];

      for (var i = 0; i < ogUsers.length; i++) {
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }
      setCurrentPage(1);
      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function qrcodegen(url, index) {
    QRCode.toDataURL(
      url,
      {
        width: 2000,
        margin: 5,
        color: { dark: "#000000FF", light: "#EEEEEEFF" },
      },
      (err, qc) => {
        if (err) return console.error(err);

        var tempUsers = users;
        tempUsers[index]["qr"] = qc;
        setUsers(tempUsers);
        setRandom(makeid(5));
      }
    );
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogUsers);
    setSearch("");
    setCurrentPage(val);
    setUsers(tempUsers.splice((val - 1) * 10, 10));
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data.splice(0, 10));
      setOGusers(res.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
  }

  function setHasBought(index, checked) {
    var tempUsers = users;
    tempUsers[index]["has_bought"] = checked;
    setUsers(tempUsers);
    setRandom(makeid(5));

    var body = {
      uid: tempUsers[index]["uid"],
      bought: checked,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "update_bought", body, config).then((res) => {});
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                padding: "100px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHieght: "120vh",
                width: "100vw",
              }}
            >
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Search User / Email"
                variant="outlined"
                value={search}
                size="small"
                sx={{
                  input: {
                    fontSize: "24px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  },
                }}
                style={{
                  height: "55px",
                  border:
                    state.auth.theme == "dark"
                      ? "2px solid " + themeStyleDark.primaryColor
                      : "2px solid " + themeStyle.primaryColor,
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,

                  marginBottom: "30px",
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                {Array.from(Array(Math.ceil(ogUsers.length / 10)).keys()).map(
                  (temp, index) => (
                    <div
                      style={{
                        cursor: "pointer",
                        margin: "3px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                        borderRadius: "30px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                      onClick={() => {
                        handleCurrentPage(index + 1);
                      }}
                    >
                      {index + 1}
                    </div>
                  )
                )}
              </div>
              <div
                style={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: "15px",
                  padding: "15px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    justifyContent: "flex-start",
                    textAlign: "left",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    paddingBottom: "20px",
                    borderBottom:
                      state.auth.theme == "dark"
                        ? "1px solid" + themeStyleDark.primaryColor
                        : "1px solid" + themeStyle.primaryColor,
                  }}
                >
                  <Grid item md={1}>
                    No.
                  </Grid>
                  <Grid item md={4}>
                    Full Name
                  </Grid>
                  <Grid item md={3}>
                    Email
                  </Grid>
                  <Grid item md={1}>
                    Date
                  </Grid>
                  <Grid item md={1}>
                    Profiles
                  </Grid>
                  <Grid item md={1}>
                    Has Card
                  </Grid>
                  <Grid item md={1}>
                    Download QR
                  </Grid>
                </Grid>
                {users.map((user, index) => (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      color: "white",
                      padding: "15px",
                    }}
                  >
                    <Grid item md={1}>
                      {(currentPage - 1) * 10 + (index + 1)}
                    </Grid>
                    <Grid
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/contacts", {
                          replace: false,
                          state: { uid: user.uid, name: user["full_name"] },
                        })
                      }
                      item
                      md={3}
                    >
                      {user["full_name"]}
                    </Grid>
                    <Grid item md={4}>
                      {user["email"]}
                    </Grid>
                    <Grid item md={1}>
                      {user["date"].replace("00:00", "")}
                    </Grid>
                    <Grid item md={1} style={{ textAlign: "center" }}>
                      {user["profiles"]}
                    </Grid>
                    <Grid item md={1} style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-flex",
                          marginLeft: "-15px",
                        }}
                      >
                        <Checkbox
                          checked={user["has_bought"]}
                          labelStyle={{
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                          iconStyle={{
                            fill:
                              state.auth.theme == "light"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                          }}
                          onChange={(e) =>
                            setHasBought(index, e.target.checked)
                          }
                          value={1}
                          color={"primary"}
                          style={{
                            marginTop: "-6px",
                            marginLeft: "3px",
                            transform: "scale(1.25)",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        />
                        <span
                          style={{
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            marginTop: "-2px",
                            marginLeft: "10px",
                            fontSize: "20px",
                          }}
                        >
                          {user["has_bought"] ? "PAID" : "FREE"}
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {user["qr"].length == 0 ? (
                        <Button
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            qrcodegen(
                              "https://profile.techcards.in/" +
                                user["profile_link"] +
                                "/" +
                                user["uid"],
                              index
                            );
                          }}
                        >
                          <QrCodeIcon
                            style={{
                              fontSize: "30px",
                              marginTop: "-5px",
                              color: "white",
                              textAlign: "center",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button
                          style={{ cursor: "pointer" }}
                          href={user["qr"]}
                          download={user["profile_link"] + ".png"}
                        >
                          <DownloadIcon
                            style={{
                              marginTop: "-5px",
                              fontSize: "30px",
                              color: "white",
                              textAlign: "center",
                            }}
                          />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Profiles;
