import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [paidUsers, setPaidUsers] = useState(0);
  const [freeUsers, setFreeUsers] = useState(0);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
      setSelectedNoti("EVERYONE");
    } else {
      var tempUsers = [];
      setSelectedNoti("SEARCH");
      for (var i = 0; i < ogUsers.length; i++) {
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }

      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  function setChecked(index, checked) {
    var tempUsers = users;
    tempUsers[index]["checked"] = checked;
    setUsers(tempUsers);
    setRandom(makeid(5));
  }

  function sendNotification() {
    if (subject.length == 0 || message.length == 0) {
      createAlert("ERROR", "Error!", "Please add subject and message.");
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };

      var tempUsers = [];

      for (var i = 0; i < users.length; i++) {
        if (users[i]["checked"] == true) {
          tempUsers.push(users[i]);
        }
      }

      body["send_to"] = selectedNoti;
      body["title"] = subject;
      body["message"] = message;
      body["users"] = tempUsers;

      axios.post(API_URL + "send_notification", body, config).then((res) => {
        createAlert("SUCCESS", "Sent.", "Notification sent!");
      });
    }
  }

  function getData() {
    axios.get(API_URL + "get_dashboard").then((res) => {
      setTotalUsers(res.data["total_users"]);
      setFreeUsers(res.data["free_users"]);
      setPaidUsers(res.data["paid_users"]);
      var body = {
        email: state.auth.user.email,
      };
      const config = {
        headers: {
          Authorization: state.auth.token,
          "Content-Type": "application/json",
        },
      };
      axios.post(API_URL + "get_users", body, config).then((res) => {
        var usersTemp = res.data;
        for (var i = 0; i < res.data.length; i++) {
          usersTemp[i]["checked"] = false;
        }
        setUsers(usersTemp);
        setOGusers(usersTemp);
        setIsLoading(false);
      });
    });
  }

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  var data = {
    labels: ["Total", "Paid", "Free"],
    datasets: [
      {
        label: "# of Votes",
        data: [totalUsers, paidUsers, freeUsers],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                padding: "150px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "120vh",
                width: "100vw",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Link
                    to={{
                      pathname: "/orders",
                      search: "?tab=PENDING",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <CardActionArea
                      sx={[
                        {
                          height: "25vh",
                          borderRadius: "15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,

                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&& .MuiTouchRipple-child": {
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          },
                        }),
                      ]}
                      style={{
                        padding: "17px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                            width: "40%",
                            paddingLeft: "10%",
                          }}
                        >
                          <PersonIcon
                            style={{
                              padding: "10px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              border:
                                state.auth.theme == "dark"
                                  ? "2px solid " + themeStyleDark.primaryColor
                                  : "2px solid " + themeStyle.primaryColor,
                              borderRadius: "10px",
                              textAlign: "center",
                              fontSize: "80px",
                              marginTop: "-10%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "60%",
                            height: "100%",
                            paddingRight: "20%",

                            backgroundImage:
                              state.auth.theme == "dark"
                                ? "radial-gradient(circle, rgba(255,0,0,0.3) 10%, " +
                                  themeStyleDark.primaryCardColor +
                                  ", " +
                                  themeStyleDark.primaryCardColor +
                                  ")"
                                : "radial-gradient(red, " +
                                  themeStyle.primaryCardColor +
                                  ")",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "25px",
                              fontFamily: "Bahnschrift",
                            }}
                          >
                            Total Users
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "60px",
                              fontWeight: "bold",
                              spacing: "5px",
                              fontFamily: "Kanit",
                            }}
                          >
                            {totalUsers}
                          </div>
                        </div>
                      </div>
                    </CardActionArea>
                  </Link>
                </Grid>
                <Grid item md={4}>
                  <Link
                    to={{
                      pathname: "/orders",
                      search: "?tab=PENDING",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <CardActionArea
                      sx={[
                        {
                          height: "25vh",
                          borderRadius: "15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,

                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&& .MuiTouchRipple-child": {
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          },
                        }),
                      ]}
                      style={{
                        padding: "17px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                            width: "40%",
                            paddingLeft: "10%",
                          }}
                        >
                          <ReceiptIcon
                            style={{
                              padding: "10px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              border:
                                state.auth.theme == "dark"
                                  ? "2px solid " + themeStyleDark.primaryColor
                                  : "2px solid " + themeStyle.primaryColor,
                              borderRadius: "10px",
                              textAlign: "center",
                              fontSize: "80px",
                              marginTop: "-10%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "60%",
                            height: "100%",
                            paddingRight: "20%",

                            backgroundImage:
                              state.auth.theme == "dark"
                                ? "radial-gradient(circle, rgba(0,0,255,0.3) 10%, " +
                                  themeStyleDark.primaryCardColor +
                                  ", " +
                                  themeStyleDark.primaryCardColor +
                                  ")"
                                : "radial-gradient(blue, " +
                                  themeStyle.primaryCardColor +
                                  ")",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "25px",
                              fontFamily: "Bahnschrift",
                            }}
                          >
                            Paid Users
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "60px",
                              fontWeight: "bold",
                              spacing: "5px",
                              fontFamily: "Kanit",
                            }}
                          >
                            {paidUsers}
                          </div>
                        </div>
                      </div>
                    </CardActionArea>
                  </Link>
                </Grid>
                <Grid item md={4}>
                  <Link
                    to={{
                      pathname: "/orders",
                      search: "?tab=PENDING",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <CardActionArea
                      sx={[
                        {
                          height: "25vh",
                          borderRadius: "15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,

                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&& .MuiTouchRipple-child": {
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          },
                        }),
                      ]}
                      style={{
                        padding: "17px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                            width: "40%",
                            paddingLeft: "10%",
                          }}
                        >
                          <CurrencyRupeeIcon
                            style={{
                              padding: "10px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              border:
                                state.auth.theme == "dark"
                                  ? "2px solid " + themeStyleDark.primaryColor
                                  : "2px solid " + themeStyle.primaryColor,
                              borderRadius: "10px",
                              textAlign: "center",
                              fontSize: "80px",
                              marginTop: "-10%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "60%",
                            height: "100%",
                            paddingRight: "20%",

                            backgroundImage:
                              state.auth.theme == "dark"
                                ? "radial-gradient(circle, rgba(0,255,0,0.3) 0%, " +
                                  themeStyleDark.primaryCardColor +
                                  ", " +
                                  themeStyleDark.primaryCardColor +
                                  ")"
                                : "radial-gradient(green, " +
                                  themeStyle.primaryCardColor +
                                  ")",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "25px",
                              fontFamily: "Bahnschrift",
                            }}
                          >
                            Free Users
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "60px",
                              fontWeight: "bold",
                              spacing: "5px",
                              fontFamily: "Kanit",
                            }}
                          >
                            {freeUsers}
                          </div>
                        </div>
                      </div>
                    </CardActionArea>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "20px",
                      borderRadius: "15px",
                      marginTop: "7px",
                      height: "100%",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "5px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        marginBottom: "35px",
                      }}
                    >
                      Send Notifications
                    </h2>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item md={2}>
                        {selectedNoti == "EVERYONE" ? (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "black",
                              height: "45px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "45px",
                              backgroundColor: "#121212",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {selectedNoti == "FREE USERS" ? (
                          <Button
                            fullWidth
                            value="FREE USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "black",
                              height: "45px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              FREE USERS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="FREE USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "45px",
                              backgroundColor: "#121212",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              FREE USERS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={3}>
                        {selectedNoti == "PAID USERS" ? (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "black",
                              height: "45px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "45px",
                              backgroundColor: "#121212",
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "24px",
                                fontSize: "18px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          placeholder="Search User / Email"
                          variant="outlined"
                          value={search}
                          size="small"
                          sx={{
                            input: {
                              fontSize: "19px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            },
                          }}
                          style={{
                            marginTop: "-15px",
                            height: "46px",
                            borderRadius: "5px",
                            border:
                              state.auth.theme == "dark"
                                ? "1px solid " + themeStyleDark.primaryColor
                                : "1px solid " + themeStyle.primaryColor,
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,
                            marginBottom: "30px",
                          }}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Subject"
                      variant="outlined"
                      value={subject}
                      size="small"
                      sx={{
                        input: {
                          fontSize: "24px",
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }}
                      style={{
                        height: "55px",
                        border:
                          state.auth.theme == "dark"
                            ? "2px solid " + themeStyleDark.primaryColor
                            : "2px solid " + themeStyle.primaryColor,
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,
                      }}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <br></br> <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Message"
                      variant="outlined"
                      size="small"
                      sx={{
                        input: {
                          fontSize: "24px",
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }}
                      style={{
                        height: "55px",
                        border:
                          state.auth.theme == "dark"
                            ? "2px solid " + themeStyleDark.primaryColor
                            : "2px solid " + themeStyle.primaryColor,
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <br></br> <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          fontSize: "18px",
                          marginTop: "25px",
                          height: "45px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                        onClick={() => sendNotification()}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            color:
                              state.auth.theme == "light"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                          }}
                        >
                          Send notification
                        </span>
                        <ChevronRightIcon
                          style={{
                            fontSize: "30px",
                            color:
                              state.auth.theme == "light"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            marginRight: "-10px",
                          }}
                        />
                      </Button>
                    </div>
                    <br></br> <br></br>
                    {search.length != 0 ? (
                      <div>
                        {users.map((user, index) => (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              height: "30px",
                              borderRadius: "30px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          >
                            <div>
                              {user["full_name"]}
                              {user["email"]}
                            </div>
                            <Checkbox
                              checked={user["checked"]}
                              labelStyle={{
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                              iconStyle={{
                                fill:
                                  state.auth.theme == "light"
                                    ? themeStyleDark.textColor
                                    : themeStyle.textColor,
                              }}
                              onChange={(e) =>
                                setChecked(index, e.target.checked)
                              }
                              value={1}
                              color={"primary"}
                              style={{
                                marginTop: "-6px",
                                marginLeft: "3px",
                                transform: "scale(1.25)",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "500px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "50px",
                      borderRadius: "15px",
                      marginTop: "7px",
                    }}
                  >
                    <Doughnut data={data} options={{ cutout: "80%" }} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{
            color:
              state.auth.theme == "dark"
                ? themeStyleDark.primaryColor
                : themeStyle.primaryColor,
          }}
        />
      </div>
    );
  }
}

export default Dashboard;
